import React from 'react';
import * as styles from './process.module.scss';

import layer from '../../../images/Projects/Layer.png';

const Process = ({number, title, description, list}) => {

    const numbers = list;
    const listItems = numbers.map((numbers) =>
        <div className={styles.divLayer}>
            <img src={layer} alt='Check' className={styles.Layer} /> 
            <p>{numbers}</p>
        </div>

    );
    return (
        <div className={styles.detalle}>
            <div                                     
                data-sal-delay='400'
                data-sal='zoom-in'
                data-sal-duration='500'
                data-sal-easing='ease-out-back'
                className={styles.containter}>
                <div className={styles.title}> 
                    <div className={styles.number}>
                        <p>{number}</p>
                    </div>
                    <div className={styles.name}>
                        <h3>{title}</h3> 
                    </div>
                </div>
                <div className={styles.description}>
                    <p>{description}</p>
                    <div className = {styles.checklist}>                                        
                        {listItems}
                    </div>                    
                </div>
            </div>            
        </div>
    );

};
export default Process;