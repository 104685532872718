import React, { Fragment } from 'react';
import * as styles from './button.module.scss';
import { Link } from 'gatsby';
const button = ({ children, type, isLink, linkTo }) => {
	let typeBtn = type === 'black';
	let buttonType = (
		<button
			data-sal-delay='300'
			data-sal='slide-up'
			data-sal-duration='700'
			data-sal-easing='ease-out-back'
			className={`${styles.button} ${typeBtn ? styles.gray : styles.black}`}>
			{children}
		</button>
	);
	let linkType = (
		<Link
			data-sal-delay='300'
			data-sal='slide-up'
			data-sal-duration='700'
			data-sal-easing='ease-out-back'
			className={`${styles.a} ${typeBtn ? styles.gray : styles.black}`}
			to={linkTo}>
			{children}
		</Link>
	);

	return <Fragment> {isLink ? linkType : buttonType}</Fragment>;
};
export default button;
