import React from 'react';
import * as styles from './square.module.scss';

const Square = ({title, subtitle, description, list, color, image}) => {
    
    const numbers = list;
    const listItems = numbers.map((numbers) =>
        <div className={styles.span}>
            <p>{numbers}</p>
        </div>

    );
    const colorTitle = color;

    return (
        <div className={styles.containerInfo} 
            data-sal-delay='400'
            data-sal='zoom-in'
            data-sal-duration='500'
            data-sal-easing='ease-out-back'>
            <div className={styles.header}>
                <div className={styles.icon}>
                    <img src={image} alt={title} /> 
                </div>    
                <div className={styles.textos}> 
                    <div className={styles.encapsulado}>
                        {listItems}
                    </div>                                    
                    <div className={styles.title}>
                        <h1>{title}</h1>
                    </div>
                    <div className={styles.titleColor}>
                        <p style={{'--my-color-var': colorTitle}} >{subtitle}</p>
                    </div>
                    <div className={styles.description}>
                        <p>{description}
                        </p>
                    </div>
                </div>
            </div>                            
        </div>
    );
};
export default Square;