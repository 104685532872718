import React from 'react';
import * as styles from '../projects.module.scss'
import Tabs from  '../TabsCasosExito/tabs';
import Process from '../ProcessCasosExito/process';
import Square from '../SquareCasosExito/square';
import imgFirmaDigital from '../../../images/Sure/firmaDigital.png';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const FirmaDigitalSeccion = () => {

    const {t} = useTranslation();

    return (
        <section className={styles.projectSection}>
            <div className={styles.containProject}>
                <div className={styles.containBody}>
                    <div className={styles.contentInfoLeft}>                        
                        <Tabs
                         active = 'Firma digital'/>

                        <Square
                            title = {t("surePage.firmaDigitalPage.title")}
                            subtitle= {t("surePage.firmaDigitalPage.subtitle")}
                            description = {t("surePage.firmaDigitalPage.description")}
                            list = {[]}                                               
                            color = '#8653d6'
                            image = {imgFirmaDigital}
                        />                       
                    </div>
                    <div className={styles.contentInfoRight}>                            
                        <Process 
                            title = {t("surePage.firmaDigitalPage.challenge.title")}
                            description = {t("surePage.firmaDigitalPage.challenge")}
                            list= {[]}
                        />
                        <Process 
                            title = {t("surePage.firmaDigitalPage.solution.title")}
                            description = {t("surePage.firmaDigitalPage.solution")}
                            list= {[]}
                        />
                    </div>
                </div>
            </div>
		</section>
    );
};
export default FirmaDigitalSeccion;