import React from 'react';
import * as styles from './tabs.module.scss';

import Button from '../../UI/Button/button';
// import { Link } from 'gatsby';
import { useTranslation, Link } from 'gatsby-plugin-react-i18next';

import firmaDigital from '../../../images/Sure/firmaDigital.png';
import cotizacionAsinc from '../../../images/Sure/cotizadorAsinc.png';
import mvp from '../../../images/Sure/Mvp.png';

const Tabs = ({active}) => {
    let estado = active;

    const {t} = useTranslation();

	return (
        <div className={styles.contTabs} >
            <div className={`${styles.containBtn} ${estado === 'Firma digital' ? styles.containBtnsel : styles.nosel}`}>
                <Button linkTo='/projects/firmadigital' isLink type='black' >
                    <Link to='/projects/firmadigital'>
                        <img src={firmaDigital} alt='firmaDigital' /> 
                    </Link>
                    {"Firma digital"}
                </Button>
            </div>
            <div className={`${styles.containBtn} ${estado === 'cotizacion asincronica' ? styles.containBtnsel : styles.nosel}`}>
                <Button linkTo='/projects/cotizacionAsincronica' isLink type='black' >
                    <Link to='/projects/cotizacionAsincronica'>
                        <img src={cotizacionAsinc} alt='Cotización asincrónica' /> 
                    </Link>
                    {"Cotización asincrónica"}
                </Button>
            </div>
            <div className={`${styles.containBtn} ${estado === 'desarrolloMvp' ? styles.containBtnsel : styles.nosel}`}>
                <Button linkTo='/projects/desarrolloMvp' isLink type='black'>
                    <Link to='/projects/desarrolloMvp'>
                        <img src={mvp} alt='Desarrollo MVP' /> 
                    </Link>
                    {"Desarrollo MVP"}
                </Button>
            </div>
        </div>


    );
};

export default Tabs;